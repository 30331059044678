import { Body, Icon, useTheme } from "@merit/frontend-components";
import { NotificationLayout } from "../NotificationLayout";
import { Pressable, StyleSheet } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useNotificationsController } from "../Notifications";
import { useRoute } from "@react-navigation/native";
import type { FC } from "react";
import type { ImageStyle, TextStyle, ViewStyle } from "react-native";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "NotificationEmpty";

export const NotificationEmpty: FC = () => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const route = useRoute();
  const { _ } = useLingui();
  const { refreshNotifications } = useNotificationsController();

  const styles = StyleSheet.create<{
    readonly icon: ImageStyle;
    readonly marginBottom: ViewStyle;
    readonly refreshButton: TextStyle;
  }>({
    icon: {
      height: 32,
      width: 32,
    },
    marginBottom: {
      marginBottom: theme.spacing.s,
    },
    refreshButton: {
      color: theme.colors.text.link,
    },
  });

  const handlePressRefresh = () => {
    refreshNotifications();
  };

  return (
    <NotificationLayout
      body={
        <Body
          numberOfLines={3}
          style={styles.marginBottom}
          testProps={{
            elementId: "description",
            elementName,
            screenName: route.name,
          }}
        >
          <Trans>
            No new notifications.{" "}
            <Pressable onPress={handlePressRefresh} style={styles.refreshButton}>
              <Body>Press to refresh</Body>
            </Pressable>
          </Trans>
        </Body>
      }
      heading={_(msg`You're looking good`)}
      leftIcon={
        <Icon
          name="notificationDesktopEmpty"
          style={styles.icon}
          {...getTestProps({ elementId: "leftIcon", elementName })}
        />
      }
    />
  );
};

import { Body, Icon, useTheme } from "@merit/frontend-components";
import { NotificationLayout } from "../NotificationLayout";
import { Pressable, StyleSheet } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { TruncatedBody } from "@src/components";
import { useGetTestProps, useNavigation, useRoute } from "@src/hooks";
import { useLingui } from "@lingui/react";
import a from "indefinite";
import type { FC } from "react";
import type { NotificationMeritProps } from "./types";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "NotificationMerit";

export const NotificationMerit: FC<NotificationMeritProps> = ({ merit, onClose }) => {
  const getTestProps = useGetTestProps();
  const navigation = useNavigation();
  const route = useRoute();
  const { theme } = useTheme();
  const { _ } = useLingui();

  const styles = StyleSheet.create({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    closeIcon: {
      height: 20,
      width: 20,
    },
    icon: {
      height: 32,
      width: 32,
    },
  });

  const handleClose = () => {
    onClose?.(merit);
  };

  const handlePress = () => {
    navigation.navigate("MeritDetails", { meritId: merit.id });
  };

  const meritName = merit.name ?? "";
  const orgName = merit.transformedFields.orgName;
  const article = a(merit.name ?? "", { articleOnly: true });

  return (
    <Pressable
      onPress={handlePress}
      {...getTestProps({ elementId: merit.id, elementName }, { componentName: "Container" })}
    >
      <NotificationLayout
        body={
          <TruncatedBody
            numberOfLines={2}
            {...getTestProps({ elementId: merit.id, elementName }, { componentName: "Body" })}
          >
            <Trans comment={`article is either "a" or "an", depending on meritName`}>
              You have received {article}&nbsp;
              <Body style={styles.bold}>{meritName}</Body>&nbsp;merit from&nbsp;
              <Body style={styles.bold}>{orgName}</Body>
            </Trans>
          </TruncatedBody>
        }
        heading={_(msg`You received a merit`)}
        leftIcon={
          <Icon
            name="notificationDesktop"
            style={styles.icon}
            testProps={{
              elementId: "icon",
              elementName,
              screenName: route.name,
            }}
          />
        }
        rightIcon={
          <Pressable
            onPress={handleClose}
            {...getTestProps(
              {
                elementId: merit.id,
                elementName,
              },
              { componentName: "CloseButton" }
            )}
          >
            <Icon name="closeSmallDefault" style={styles.closeIcon} />
          </Pressable>
        }
      />
    </Pressable>
  );
};

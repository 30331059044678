import { Body, Heading, Icon, useTheme } from "@merit/frontend-components";
import { BottomSlideUpModal } from "../BottomSlideUpModal";
import { Header } from "../Header";
import { Pressable, StyleSheet, View } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useState } from "react";
import type { ViewStyle } from "react-native";

export const WhatIsShared = () => {
  const { bottom: bottomSafeInset } = useSafeAreaInsets();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly bottomSpace: ViewStyle;
    readonly helpContainer: ViewStyle;
    readonly helpIconContainer: ViewStyle;
    readonly modalContentContainer: ViewStyle;
    readonly modalHeader: ViewStyle;
  }>({
    bottomSpace: {
      marginBottom: theme.spacing.xxl,
    },
    helpContainer: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
    },
    helpIconContainer: {
      marginRight: theme.spacing.s,
    },
    modalContentContainer: {
      padding: theme.spacing.xxl,
      paddingBottom: Math.max(theme.spacing.xxl, bottomSafeInset),
    },
    modalHeader: {
      paddingBottom: theme.spacing.xxl,
      paddingHorizontal: 0,
      paddingTop: 0,
    },
  });

  const [isHelpVisible, setIsHelpVisible] = useState(false);

  const getTestProps = useGetTestProps();
  const { _ } = useLingui();

  return (
    <>
      <Pressable
        onPress={() => {
          setIsHelpVisible(true);
        }}
        style={styles.helpContainer}
        {...getTestProps({
          elementId: "pressable",
          elementName: "WhatIsShared",
        })}
      >
        <View style={styles.helpIconContainer}>
          <Icon name="helpOutlinedMediumDefault" />
        </View>
        <Body>
          <Trans>What is shared?</Trans>
        </Body>
      </Pressable>
      {isHelpVisible ? (
        <BottomSlideUpModal
          appearanceType="card"
          contentContainerStyle={styles.modalContentContainer}
          onClose={() => {
            setIsHelpVisible(false);
          }}
        >
          <Header
            containerStyle={styles.modalHeader}
            leftElement={<Icon name="closeLargeAction" />}
            leftOnPress={() => {
              setIsHelpVisible(false);
            }}
          />
          <Heading
            bold
            level="2"
            style={styles.bottomSpace}
            {...getTestProps({
              elementId: "header",
              elementName: "WhatIsShared",
            })}
          >
            <Trans>What is shared?</Trans>
          </Heading>
          <Body
            {...getTestProps({
              elementId: "subHeader",
              elementName: "WhatIsShared",
            })}
            size="l"
            style={styles.bottomSpace}
          >
            <Trans>
              When checking in, your Merit QR Code will only share three key components as proof of
              your&nbsp;identity.
            </Trans>
          </Body>
          <Body
            {...getTestProps({
              elementId: "identityComponents",
              elementName: "WhatIsShared",
            })}
            size="l"
            style={styles.bottomSpace}
          >
            {[
              _(msg({ message: "Your first and last name" })),
              _(msg({ message: "Your primary email" })),
              _(msg({ message: "Your member ID" })),
            ]
              .map(str => `  •  ${str}`)
              .join("\n")}
          </Body>
          <Body
            {...getTestProps({
              elementId: "footer",
              elementName: "WhatIsShared",
            })}
            size="l"
          >
            <Trans>Merit QR Code doesn't contain any of your&nbsp;merits</Trans>
          </Body>
        </BottomSlideUpModal>
      ) : null}
    </>
  );
};

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Icon } from "@src/components";
import { Pressable, StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useGetTestProps, useRoute } from "@src/hooks";
import { useNotificationsController } from "../Notifications";
import { withOpacity } from "@src/utils";
import type { TextStyle, ViewStyle } from "react-native";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "NotificationEmpty";

export const NotificationEmpty = () => {
  const getTestProps = useGetTestProps();
  const route = useRoute();
  const { theme } = useTheme();
  const { refreshNotifications } = useNotificationsController();

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly heading: ViewStyle;
    readonly iconContainer: ViewStyle;
    readonly refreshButton: TextStyle;
  }>({
    container: {
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      paddingLeft: theme.spacing.xl,
    },
    heading: {
      marginBottom: theme.spacing.s,
    },
    iconContainer: {
      alignItems: "center",
      backgroundColor: withOpacity(theme.colors.brand.oceanBlue, 0.1),
      borderRadius: 35,
      height: 70,
      justifyContent: "center",
      marginRight: theme.spacing.m,
      width: 70,
    },
    refreshButton: {
      color: theme.colors.text.link,
    },
  });

  const handlePressRefresh = () => {
    refreshNotifications();
  };

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName,
      })}
    >
      <View style={styles.iconContainer}>
        <Icon color={theme.colors.brand.oceanBlue} name="notifications" size={48} />
      </View>
      <View>
        <Heading
          bold
          level="3"
          style={styles.heading}
          testProps={{
            elementId: "heading",
            elementName,
            screenName: route.name,
          }}
        >
          <Trans>You're looking good</Trans>
        </Heading>
        <Body
          testProps={{
            elementId: "description",
            elementName,
            screenName: route.name,
          }}
        >
          <Trans>
            No new notifications.{" "}
            <Pressable onPress={handlePressRefresh} style={styles.refreshButton}>
              <Body>Press to refresh</Body>
            </Pressable>
          </Trans>
        </Body>
      </View>
    </View>
  );
};
